<template>
  <v-card>
    <v-card-title>{{ $t("page.forgotPassword.title") }}</v-card-title>
    <v-card-text>
      <v-form v-model="form">
        <v-text-field
          v-model="email"
          :label="$t('page.forgotPassword.email.title')"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn text :to="toLogin">{{
        $t("page.forgotPassword.actions.login")
      }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn large class="primary px-12" @click="sendPasswordRecovery()">{{
        $t("page.forgotPassword.actions.send")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import * as Routes from "../../router/route-names";
export default {
  components: {},
  data() {
    return {
      form: null,
      email: null,
    };
  },
  computed: {
    toLogin() {
      return Routes.LOGIN;
    },
  },
  watch: {},
  methods: {
    sendPasswordRecovery() {
      this.$store.dispatch("auth/sendPasswordRecoveryEmail", {
        email: this.email,
      });
    },
  },
};
</script>